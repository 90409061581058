/*
$(document).on('turbolinks:load', function() {
	try{
		$("#phone").intlTelInput({
		    formatOnInit: true,
		    formatOnDisplay: true,
		    separateDialCode: true,
		    preferredCountries: ['ml'],
		    utilsScript: require("../vendor/backend/bracket/lib/intl-tel-input/build/js/utils.js"),
		    //utilsScript: reauire("../vendor/bracket/lib/libphonenumber/utils.js"),
		    
	  	});
  	} catch (e) {

  }
});



$(document).on('turbolinks:load', function() {
  $('body').on('shown.bs.modal', '.modal', function() {
    try{
    $("#phone").intlTelInput({
        formatOnInit: true,
        formatOnDisplay: true,
        separateDialCode: true,
        preferredCountries: ['ml'],
        utilsScript: require("../vendor/backend/bracket/lib/intl-tel-input/build/js/utils.js"),
        //utilsScript: reauire("../vendor/bracket/lib/libphonenumber/utils.js"),
        
      });
    } catch (e) {

  }
  });
});
*/
$(document).on('turbolinks:load', function() {
  var phoneInput = document.querySelector("#phone");
  if (phoneInput){
    window.intlTelInput(phoneInput,{
      // any initialisation options go here
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.3.0/js/utils.js",
      //utilsScript: require("intl-tel-input/build/js/utils"),
      countrySearch: true,
      separateDialCode: true,
      strictMode: true,
      validationNumberType: "MOBILE",
      placeholderNumberType: "MOBILE",
      initialCountry: "auto",
      allowDropdown: true,
      formatOnDisplay: true,
      formatAsYouType: true,
      autoPlaceholder: "aggressive",
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("ml"));
      },
      
    });
    //window.intlTelInputGlobals.getInstance(phoneInput).getNumber();
  }

  });